import styled from "styled-components";

const Article = styled.article`
  p:not(.label) {
    text-indent: 2em;
    padding: 1rem 0;
  }
  img {
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 0.5rem;
  }
  .label {
    color: #01d267;
    font-weight: bold;
    padding-top: 1rem;
  }
`;

const NewsDetail = function (props) {
  const { detail } = props;
  return (
    <section className=" flex-shrink-0 text-center px-4">
      <h2 className="text-2xl text-main-green-400 font-bold">{detail.title}</h2>
      <p className="text-sm text-gray-300 py-4 border-main-green-400 border-b-2">
        文章来源：{detail.source} 发布时间：{detail.time}
      </p>
      <Article
        className="text-left"
        dangerouslySetInnerHTML={{ __html: detail.content }} // 插入 HTML 内容
      ></Article>
    </section>
  );
};

export default NewsDetail;
