import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

/* 引入图片 */
import Logo from "../../assets/image/index/logo@2x.png";
import Icon_phone from "../../assets/image/index/icon_menu@2x.png";

const H1 = styled.h1`
  text-indent: -999px;
  span {
    position: absolute;
  }
`;

const NavMenu = styled.nav`
  &.active {
  }
`;

const Header = function () {
  const location = useLocation();
  const current_path = location.pathname;
  const [is_menu_dropdown, setDropDown] = React.useState(false);
  const nav_arr = [
    {
      title: "首页",
      to: "/",
    },
    {
      title: "景区合作",
      to: "/scenic_cooperation",
    },
	{
	  title: "智慧社区",
	  to: "/community_cooperation",
	},
    {
      title: "商家接入",
      to: "/store_linking",
    },
    {
      title: "生态合作",
      to: "/ecosystem_cooperation",
    },
    {
      title: "最新资讯",
      to: "/news",
    },
    {
      title: "关于我们",
      to: "/about",
    },
  ];
  function toggleMenu() {
    setDropDown(!is_menu_dropdown);
  }
  return (
    <header className="">
      <nav className="flex items-center relative z-10 bg-white">
        <Link to="/" className=" mr-auto text px-4 py-2">
          <H1>
            <span>景区码</span>
            <img src={Logo} alt="景区码" className="w-28" />
          </H1>
        </Link>
        {!is_menu_dropdown ? (
          <div className="px-4 py-2" onClick={toggleMenu}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-6"
            >
              <path
                fillRule="evenodd"
                d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        ) : (
          <div className="px-4 py-2" onClick={toggleMenu}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-6"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
      </nav>
      <NavMenu
        className={`divide-y divide-gray-200 bg-gray-50 shadow-inner transform absolute w-full py-2 ${
          is_menu_dropdown
            ? " translate-y-0  transition-all "
            : " -translate-y-96"
        }`}
      >
        {nav_arr.map((e, i) => {
          return (
            <Link
              to={e.to}
              key={i}
              className="flex justify-between px-4 py-2"
              onClick={toggleMenu}
            >
              <span>{e.title}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-4"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
          );
        })}
      </NavMenu>
    </header>
  );
};

export default Header;
