import { Link, useLocation } from "react-router-dom";
import Banner from "../../component/Banner/index";
import styled from "styled-components";
import React from "react";

import banner_img from "../../assets/image/news/poster.png";

import axios from "axios";
import List from "./list";
import NewsDetail from "./detail";

const Navbar = styled.section`
  a {
    background-color: #327fdb;
    transition: all 0.15s;
    &.active {
      background-color: #0a54ab;
    }
    &:hover,
    &:focus {
      background-color: #0a54ab;
    }
  }
`;

/* 获取页面参数 */
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/* 容器 */
const News = function () {
  const { pathname } = useLocation(); // 当前路径
  const query = useQuery(); // 页面参数

  const category = query.get("category");
  const news_id = query.get("news_id");

  return (
    <NewsRenderer
      news_id={news_id}
      pathname={pathname}
      category={category}
    ></NewsRenderer>
  );
};
class NewsRenderer extends React.Component {
  constructor(props) {
    super(props);
    let news_categories = [
      {
        cate_id: 0,
        cate_name: "全部",
      },
    ];

    this.state = {
      news_list: [],
      news_detail: {},
      news_categories,
      category_index: 0,
      category_id: props.category ?? 0,
      page_count: 2,
      page: 1,
    };

    this.setCategory().then(() => {
      // 新闻列表基于当前分类索引
      this.setNewsList();
    });
    const { news_id } = props;
    if (news_id) {
      this.handleClickArticle(news_id);
    }
  }
  // 获取分类
  async fetchCategory() {
    let data = await axios("v1/news/get-cate");
    return data;
  }
  // 设置分类
  async setCategory() {
    return new Promise(async (resolve, reject) => {
      try {
        const { data: news_categories } = await this.fetchCategory();
        this.setState(
          {
            news_categories: this.state.news_categories.concat(news_categories),
          },
          () => {
            resolve();
          }
        );
      } catch (error) {
        reject();
      }
    });
  }
  // 获取新闻数据
  async fetchNewsList() {
    const state = this.state;
    const cate_id = state.category_id;

    // 设置分类选中项状态
    state.news_categories.some((e, i) => {
      if (e.cate_id === cate_id) {
        this.setState({
          category_index: i,
        });
        return true;
      }
    });

    const page = state.page;

    return await axios("v1/news/get-list", {
      params: {
        page,
        page_size: 5,
        cate_id,
      },
    });
  }

  // 设置新闻数据
  async setNewsList() {
    const { data: news } = await this.fetchNewsList();
    this.setState({
      page_count: news.page_count,
      news_list: news.list,
    });
  }
  // 切换分类事件
  handleClickCategory(e, i) {
    const state = this.state;
    const { category_index } = state;

    if (category_index === i) {
      return;
    }

    const category_id = state.news_categories[i].cate_id;
    this.setState(
      {
        category_index: i,
        category_id,
        page: 1,
        page_count: 2,
      },
      () => {
        this.setNewsList();
      }
    );
  }
  /* 页数更新事件 */
  handleChangePage(value) {
    let { page_count, page } = this.state;

    if (value > page_count) {
      return;
    }

    if (value === page) {
      return;
    }

    this.setState(
      {
        page: value,
      },
      () => {
        this.setNewsList();
        this.scrollToArticleTop();
      }
    );
  }
  /* 点击文章 */
  async handleClickArticle(id) {
    const { data } = await axios("v1/news/get-detail", {
      params: {
        id,
      },
    });

    this.setState({
      news_detail: data,
    });
    this.scrollToArticleTop();
  }
  scrollToArticleTop() {
    document.documentElement.scrollTop = 200;
    document.body.scrollTop = 200;
  }
  render() {
    const { news_id, pathname } = this.props;

    return (
      <section>
        <Banner img={banner_img}></Banner>
        <Navbar className="mb-6 bg-gray-100 grid grid-cols-4">
          {this.state.news_categories.map((e, i) => {
            return (
              <Link
                to={`${pathname}?category=${e.cate_id}`}
                className={
                  "py-4 text-center text-white text-xl " +
                  (i === this.state.category_index ? "active" : "")
                }
                key={i}
                onClick={(e) => {
                  this.handleClickCategory(e, i);
                }}
              >
                {e.cate_name}
              </Link>
            );
          })}
        </Navbar>
        {news_id ? (
          <NewsDetail detail={this.state.news_detail}></NewsDetail>
        ) : (
          <List
            list={this.state.news_list}
            count={this.state.page_count}
            page={this.state.page}
            handleChangePage={(value) => this.handleChangePage(value)}
            handleClickArticle={(id) => this.handleClickArticle(id)}
          ></List>
        )}
      </section>
    );
  }
}

export default News;
