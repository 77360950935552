import Banner from "../../component/Banner/index";
import styled from "styled-components";

/* Banner 图 */
import banner_img from "../../assets/image/store_linking/poster@2x.png";

/* 商家流量图片 */
import store_flow_img from "../../assets/image/store_linking/Flowgraph@2x.png";

/* 助力商家图片 */
import empower_img1 from "../../assets/image/store_linking/Helpbusinesses1@2x.png";
import empower_img2 from "../../assets/image/store_linking/Helpbusinesses2@2x.png";

/* 合作收益图标 */
// import income_icon1 from "../../assets/image/store_linking/income1@2x.png";
// import income_icon2 from "../../assets/image/store_linking/income2@2x.png";
// import income_icon3 from "../../assets/image/store_linking/income3@2x.png";
// import income_icon4 from "../../assets/image/store_linking/income4@2x.png";
// import income_icon5 from "../../assets/image/store_linking/income5@2x.png";
// import income_icon6 from "../../assets/image/store_linking/income6@2x.png";
// import income_icon7 from "../../assets/image/store_linking/income7@2x.png";
// import income_icon8 from "../../assets/image/store_linking/income8@2x.png";
// import income_icon9 from "../../assets/image/store_linking/income9@2x.png";
import income_img from "../../assets/image/store_linking/income2.jpg";

/* 商家案例图片 */
import example_img1 from "../../assets/image/store_linking/Casestudy1@2x.png";
import example_img2 from "../../assets/image/store_linking/Casestudy2@2x.png";
// import example_img3 from "../../assets/image/store_linking/Casestudy2@2x.png";

const EmpowerStoreAside = styled.section`
  p:first-child {
    color: #5ea0da;
    font-size: 1.2rem;
  }
  > section:nth-of-type(odd) p:first-child {
    color: #5b73b6;
  }
`;
const ExampleOuter = styled.section`
  p:nth-of-type(2) {
    color: #22a837;
    background-color: #d9f6e2;
  }
`;
const StoreLinking = function () {
  const example_arr = [
    {
      title: "深圳网红餐厅",
      hot_tip: "收益提升150%！",
      desc:
        "餐厅入驻景区码以后，通过代销锁客等功能，将以往忽略的用户流量收集变现，实现长期收益增值，景区码是我们的增粉利器！",
      img: example_img1,
    },
    {
      title: "上海社区教育机构",
      hot_tip: "学员报名数提升300%！",
      desc:
        "搞教育最头疼的就是招揽学员，平时通过以往的方式用户很反感，开销大，且不能保证效果。自从加入景区码活动平台，报名学员数激增，感谢景区码！",
      img: example_img2,
    }
  ];
  return (
    <section>
      <Banner img={banner_img} path="/about/contact"></Banner>
      {/* 流量生态 */}
      <section>
        <section className="text-center py-12 px-4">
          <p className=" text-xl">构建商家流量生态</p>
          <p className=" text-lg my-4">
            景区码为商家提供线上线下一体化的服务工具，协助商家通过支付、领卡、智能应用等服务场景锁定客户，持续增加商家的长期收益。
          </p>
          <img src={store_flow_img} alt="img" />
        </section>
      </section>
      {/* 助力商家 */}
      <section className="bg-gray-100">
        <section className="py-12 px-4">
          <p className="text-xl mb-8 text-center ">景区码助力商家</p>
          <section className="flex">
            <img src={empower_img1} className="w-48 mr-4" alt="img" />
            {/* <img src={empower_img2} className="w-24 mr-2" alt="img" /> */}
            <EmpowerStoreAside className="flex flex-col justify-between">
              <section>
                <p>长期收益</p>
                <p>
                  商家通过发放商家会员卡及特惠活动快速引流，
                  提高品牌知名度及用户的粘性。
                </p>
              </section>
              <section>
                <p>轻松锁客</p>
                <p>线上线下同步导流，多场景服务，深度锁定客户。</p>
              </section>
              <section>
                <p>精准营销</p>
                <p>让大数据成为商家决策管理的利器，为商家精准导流。</p>
              </section>
              <section>
                <p>多元化收益</p>
                <p>扫码购、线上分销、京东等平台200万商品+商品一键代销。</p>
              </section>
            </EmpowerStoreAside>
          </section>
        </section>
      </section>
      {/* 合作收益 */}
      <section>
        <section className="text-center py-12 px-4">
          <p className="text-xl">合作收益</p>
          <p className="text-lg my-4">百亿流量支持/多渠道创收/码上赋能</p>
          <img src={income_img} alt="img" />
        </section>
      </section>
      {/* 商家案例 */}
      <section className="bg-gray-100">
        <section className="py-6">
          <p className="text-xl mb-6 text-center ">商家案例</p>
          <ExampleOuter className="grid grid-cols-2 gap-2 text-center px-4">
            {example_arr.map((e, i) => {
              return (
                <section key={i} className="bg-white">
                  <img src={e.img} alt="img" />
                  <p className="  text-lg px-2 pt-2">{e.title}</p>
                  <p className="text-base my-2 px-2">{e.hot_tip}</p>
                  <p className=" text-base text-left px-2 pb-2">{e.desc}</p>
                </section>
              );
            })}
          </ExampleOuter>
        </section>
      </section>
    </section>
  );
};
export default StoreLinking;
