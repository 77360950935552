import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

/* 页码组件 */
import Pagination from "@material-ui/lab/Pagination";

/* 页码组价覆盖样式 */
const PaginationStyled = styled(Pagination)`
  button {
    &:focus {
      outline: none;
    }
    &.Mui-selected {
      color: #fff;
      background-color: #01d267;
      &.MuiPaginationItem-page:hover {
        background-color: rgba(1, 210, 103, 0.8);
      }
    }
  }
`;
const List = function (props) {
  const { pathname } = useLocation();

  const news_arr = props.list;

  // 切换页码事件，通知父组件
  const handleChangePage = (e, value) => {
    props.handleChangePage(value);
  };
  return (
    <section className=" bg-gray-100 py-4">
      <section className=" divide-y divide-main-green-400 ">
        {news_arr.map((e, i) => {
          return (
            <Link
              to={`${pathname}?news_id=${e.id}`}
              className="hover:bg-white transition-all p-2 flex group"
              key={i}
              onClick={(event) => {
                props.handleClickArticle(e.id);
              }}
            >
              <img
                src={e.img}
                className="mr-2 w-40 h-24 object-cover flex-shrink-0"
                alt="img"
              />
              <article className="flex flex-col justify-between">
                <p className=" font-bold text-lg mt-2 group-hover:text-main-green-400">
                  {e.title}
                </p>
                {/* <p className="text-gray-500 my-2 flex-1 flex items-center">
                  {e.desc}
                </p> */}
                <div className="text-gray-400 text-sm flex items-center">
                  <svg
                    className="w-2 h-2 text-main-green-400 mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span>{e.time}</span>
                </div>
              </article>
            </Link>
          );
        })}
      </section>
      <PaginationStyled
        count={props.count}
        page={props.page}
        className=" flex justify-center mt-4"
        onChange={handleChangePage}
      />
    </section>
  );
};
export default List;
