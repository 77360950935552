import Banner from "../../component/Banner/index";
import styled from "styled-components";

/* Banner 图 */
import banner_img from "../../assets/image/scenic_cooperation/poster@2x.png";

/* 一码通行图片 */
import passing_img1 from "../../assets/image/scenic_cooperation/Empower1@2x.png";
import passing_img2 from "../../assets/image/scenic_cooperation/Empower2@2x.png";
import passing_img3 from "../../assets/image/scenic_cooperation/Empower3@2x.png";
import passing_img4 from "../../assets/image/scenic_cooperation/Empower4@2x.png";
import passing_img5 from "../../assets/image/scenic_cooperation/Empower5@2x.png";
import passing_img6 from "../../assets/image/scenic_cooperation/Empower6@2x.png";

/* 营销工具图片 */
import marketing_img from "../../assets/image/scenic_cooperation/tool@2x.png";

/* 周边商家生态图片 */
import store_ecosystem_img1 from "../../assets/image/scenic_cooperation/Surrounding1@2x.png";
import store_ecosystem_img2 from "../../assets/image/scenic_cooperation/Surrounding2@2x.png";
import store_ecosystem_img3 from "../../assets/image/scenic_cooperation/Surrounding3@2x.png";
import store_ecosystem_img4 from "../../assets/image/scenic_cooperation/Surrounding4@2x.png";

/* 大数据图片 */
import big_data_img from "../../assets/image/scenic_cooperation/BigData@2x.png";

/* 底部客服图片 */
import hotline_img from "../../assets/image/scenic_cooperation/hotline@2x.png";

const NearbyEcosystem = styled.section`
  padding: 2rem 9rem 0 3rem;
  font-size: 1.1rem;
  text-align: left;
  color: #44a651;
  background: url("${store_ecosystem_img2}") no-repeat right bottom #f4fdfa;
  &::before {
    content: "";
    position: absolute;
    margin-left: -30px;
    margin-top: 5px;
    width: 16px;
    height: 16px;
    background-color: #44a651;
    margin-right: 10px;
  }
`;

const ScenicCooperation = function () {
  const passing_arr = [
    {
      title: "信息推送 ",
      desc: "针对精准人群进行推广覆盖",
      img: passing_img1,
    },
    {
      title: "分时预约",
      desc: "在线预约购票，实名制助力景区抗疫",
      img: passing_img2,
    },
    {
      title: "刷码入园",
      desc: "支持刷码/刷脸入园，快速入园免排队",
      img: passing_img3,
    },
    {
      title: "刷码乘车",
      desc: "无需到站台购买，随时随地，刷码上车",
      img: passing_img4,
    },
    {
      title: "景区导游",
      desc: "语音讲解享受vip待遇，游玩不再蒙圈",
      img: passing_img5,
    },
    {
      title: "演出选座",
      desc: "景区剧场在线选座，免排队更便捷",
      img: passing_img6,
    },
  ];
  return (
    <section>
      <Banner img={banner_img} path="/about/contact"></Banner>
      {/* 赋能景区，一码通行 */}
      <section>
        <section className="text-center py-6 px-4">
          <p className="text-xl">赋能景区 一码通行</p>
          <p className="text-lg my-4">科技融入生活 让旅游 更自由</p>
          <section className="grid grid-rows-2 grid-cols-3 gap-4">
            {passing_arr.map((e, i) => {
              return (
                <section key={i} className=" text-center">
                  <img src={e.img} alt="img" />
                  <p className=" text-lg my-1">{e.title}</p>
                  <p className="text-gray-400 text-sm">{e.desc}</p>
                </section>
              );
            })}
          </section>
        </section>
      </section>
      {/* 营销工具 */}
      <section>
        <section className="text-center py-6">
          <p className="text-xl">景区码大会员营销工具</p>
          <p className="text-lg my-4">为景区增收/增粉</p>
          <img src={marketing_img} alt="img" />
        </section>
      </section>
      {/* 周边商家生态 */}
      <section>
        <section className="text-center py-6 px-4">
          <p className="text-xl">联合周边商家 共建产业生态</p>
          <p className="text-lg my-4">景区商家互相导流 实现收益最大化</p>
          <section className=" grid grid-rows-2 grid-cols-2 gap-y-2 gap-x-4">
            <img src={store_ecosystem_img1} alt="img" />
            <img src={store_ecosystem_img2} alt="img" />
            <img src={store_ecosystem_img3} alt="img" />
            <img src={store_ecosystem_img4} alt="img" />
          </section>
        </section>
      </section>
      {/* 大数据生态 */}
      <section>
        <section className="text-center py-6">
          <p className="text-xl">景区码大数据多维度呈现</p>
          <p className="text-lg my-4">让大数据成为决策者的利器</p>
          <img src={big_data_img} alt="img" />
        </section>
      </section>
      <img src={hotline_img} alt="img" />
    </section>
  );
};
export default ScenicCooperation;
