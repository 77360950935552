import { Link } from "react-router-dom";
import { Component } from "react";
/* 
  引入图片
*/
import wx_official_account_img from "../../assets/image/index/WechatQRcode@2x.png";
import weibo_img from "../../assets/image/index/WeiboQRCode@2x.png";
import douyin_img from "../../assets/image/index/DouyinQRcode@2x.png";

import wx_nol from "../../assets/image/index/icon_Wechat_nol@2x.png";
import wx_pre from "../../assets/image/index/icon_Wechat_pre@2x.png";
import weibo_nol from "../../assets/image/index/icon_Weibo_nol@2x.png";
import weibo_pre from "../../assets/image/index/icon_Weibo_pre@2x.png";
import douyin_nol from "../../assets/image/index/icon_Douyin_nol@2x.png";
import douyin_pre from "../../assets/image/index/icon_Douyin_pre@2x.png";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav_arr: [
        {
          title: "景区合作",
          to: "/scenic_cooperation",
        },
		{
		  title: "智慧社区",
		  to: "/community_cooperation",
		},
        {
          title: "商家接入",
          to: "/store_linking",
        },
        {
          title: "地区招募",
          to: "/ecosystem_cooperation",
        },
        {
          title: "最新资讯",
          to: "/news",
        },
        {
          title: "关于我们",
          to: "/about",
        },
      ],
      official_accounts: [
        {
          title: "微信",
          icon: wx_nol,
          active_icon: wx_pre,
          qr_code: wx_official_account_img,
          is_active: true,
        },
        {
          title: "微博",
          icon: weibo_nol,
          active_icon: weibo_pre,
          qr_code: weibo_img,
          is_active: false,
        },
        {
          title: "抖音",
          icon: douyin_nol,
          active_icon: douyin_pre,
          qr_code: douyin_img,
          is_active: false,
        },
      ],
      showing_qr_code: wx_official_account_img,
    };
  }

  handleFooterIconHover = (index) => {
    const official_accounts = this.state.official_accounts;
    official_accounts.forEach((e) => {
      e.is_active = false;
    });
    official_accounts[index].is_active = true;
    this.setState({
      ...this.state,
      official_accounts,
      showing_qr_code: official_accounts[index].qr_code,
    });
  };
  render() {
    return (
      <footer className="bg-gray-800 py-10">
        {/* 二维码 */}
        <img
          src={this.state.showing_qr_code}
          className="mx-auto w-3/12"
          alt="qrcode"
        />
        {/* 切换二维码图标 */}
        <ul className="flex justify-between px-28 mt-4">
          {this.state.official_accounts.map((e, i) => {
            return (
              <li
                to={e.to}
                key={i}
                onClick={(event) => {
                  this.handleFooterIconHover(i);
                }}
              >
                <img
                  src={e.is_active ? e.active_icon : e.icon}
                  className="w-10"
                  alt="icon"
                />
              </li>
            );
          })}
        </ul>

        <section className="flex justify-center items-center text-main-green-400 mt-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-6 mr-2"
          >
            <path d="M17.924 2.617a.997.997 0 00-.215-.322l-.004-.004A.997.997 0 0017 2h-4a1 1 0 100 2h1.586l-3.293 3.293a1 1 0 001.414 1.414L16 5.414V7a1 1 0 102 0V3a.997.997 0 00-.076-.383z" />
            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
          </svg>
          <a href="tel:0755-89201956" className="font-bold">
            0755-89201956
          </a>
        </section>
        <p className="text-main-green-400 text-center text-xs mt-4">
          景区码©开启码上旅游新时代
          <a href="http://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
            {" "}
            备案号: 粤ICP备16089746
          </a>
        </p>
      </footer>
    );
  }
}
export default Footer;
