import Banner from "../../component/Banner/index";
import styled from "styled-components";


/* Banner 图 */
import banner_img from "../../assets/image/community_cooperation/poster.png";

/* 社区服务 */
import passing_img1 from "../../assets/image/community_cooperation/gongnengjieshao1.png";
import passing_img2 from "../../assets/image/community_cooperation/gongnengjieshao2.png";
import passing_img3 from "../../assets/image/community_cooperation/gongnengjieshao3.png";
import passing_img4 from "../../assets/image/community_cooperation/gongnengjieshao4.png";
import passing_img5 from "../../assets/image/community_cooperation/gongnengjieshao5.png";
import passing_img6 from "../../assets/image/community_cooperation/gongnengjieshao6.png";
import passing_img7 from "../../assets/image/community_cooperation/gongnengjieshao7.png";
import passing_img8 from "../../assets/image/community_cooperation/gongnengjieshao8.png";

/*社区生态 */
import zhihui_img1 from "../../assets/image/community_cooperation/zhihui1.png";
import zhihui_img2 from "../../assets/image/community_cooperation/zhihui2.png";
import zhihui_img3 from "../../assets/image/community_cooperation/zhihui3.png";
import zhihui_img4 from "../../assets/image/community_cooperation/zhihui4.png";
import zhihui_img5 from "../../assets/image/community_cooperation/zhihui5.png";
import zhihui_img6 from "../../assets/image/community_cooperation/zhihui6.png";
import zhihui_img7 from "../../assets/image/community_cooperation/zhihui7.png";
import zhihui_img8 from "../../assets/image/community_cooperation/zhihui8.png";
import zhihui_img9 from "../../assets/image/community_cooperation/zhihui9.png";
import zhihui_img10 from "../../assets/image/community_cooperation/zhihui10.png";
import zhihui_img11 from "../../assets/image/community_cooperation/zhihui11.png";
import zhihui_img12 from "../../assets/image/community_cooperation/zhihui12.png";
/* 底部客服图片 */
import hotline_img from "../../assets/image/community_cooperation/hezuorexian.png";

/* 营销工具图片 */
import marketing_img from "../../assets/image/community_cooperation/gaodu.png";

const AppCooText = styled.article`
  position: relative;
  text-align: left;
  padding: 1rem;
  border: 1px solid #f4f4f4;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 1rem;
    width: 10px;
    height: 20px;
    background-color: #52ab5e;
  }
`;

const ScenicCooperation = function () {
  const passing_arr = [
    {
      title: "访客拜访 ",
      desc: "访客申请，业主通过后 访客获取开门二维码",
      img: passing_img1,
    },
    {
      title: "码上开门",
      desc: "访客凭借开门二维码，扫码开门",
      img: passing_img2,
    },
    {
      title: "人脸开门",
      desc: "人脸+身份认证，全程自动无需人工干预",
      img: passing_img3,
    },
    {
      title: "码上缴费",
      desc: "物业、水电煤气、取暖等 减少现场缴费排队",
      img: passing_img4,
    },
    {
      title: "共享充电",
      desc: "扫码开门，为电瓶充电，自动计费",
      img: passing_img5,
    },
    {
      title: "共享储物柜",
      desc: "扫码储物，自助存取方便",
      img: passing_img6,
    },
    {
      title: "智慧安防",
      desc: "刷码巡防，同时关联消防预警",
      img: passing_img7,
    },
    {
      title: "智慧应急",
      desc: "码上预警，紧急时刻 一键呼叫所有成员快速撤离",
      img: passing_img8,
    },
  ];
  
  const passing_arr_shengtai = [
    {
      title: "访客拜访 ",
      imgLogo: "",
      img: zhihui_img1,
    },
    {
      title: "码上开门",
      imgLogo: "",
      img: zhihui_img2,
    },
    {
      title: "人脸开门",
      imgLogo: "",
      img: zhihui_img3,
    },
    {
      title: "码上缴费",
      imgLogo: "",
      img: zhihui_img4,
    },
    {
      title: "共享充电",
      imgLogo: "",
      img: zhihui_img5,
    },
    {
      title: "共享储物柜",
      imgLogo: "",
      img: zhihui_img6,
    },
    {
      title: "智慧安防",
      imgLogo: "",
      img: zhihui_img7,
    },
    {
      title: "智慧应急",
      imgLogo: "",
      img: zhihui_img8,
    },
  	{
  	  title: "共享储物柜",
  	  imgLogo: "",
  	  img: zhihui_img9,
  	},
  	{
  	  title: "智慧安防",
  	  imgLogo: "",
  	  img: zhihui_img10,
  	},
  	{
  	  title: "智慧应急",
  	  imgLogo: "",
  	  img: zhihui_img11,
  	},
  	{
  	  title: "智慧应急",
  	  imgLogo: "",
  	  img: zhihui_img12,
  	},
  ];
  return (
    <section>
      <Banner img={banner_img} path="/about/contact"></Banner>
      {/* 社区服务模式 */}
	  
      <section>
        <section className="text-center py-6 px-4">
          <p className="text-xl">功能介绍</p>
          <p className="text-lg my-4">最新社区服务模式，提升管理能力，管理服务一体化，升级为智慧小区</p>
          <section className="grid grid-rows-2 grid-cols-3 gap-4">
            {passing_arr.map((e, i) => {
              return (
                <section key={i} className=" text-center">
                  <img src={e.img} alt="img" />
                  <p className=" text-xl my-1">{e.title}</p>
                  <p className="text-gray-400 text-sm">{e.desc}</p>
                </section>
              );
            })}
          </section>
        </section>
      </section>
      {/* 社区生态 */}
      <section>
        <section className="text-center py-6 px-4">
          <p className="text-xl">智慧社区生态</p>
          <p className="text-lg my-4">通过微信、短信、电话、APP、小程序等与园区社区人员保持常态化沟通 可码上管理整个社区，提升工作效率和管理服务质量</p>
          <section className="grid grid-rows-3 grid-cols-3 gap-4">
            {passing_arr_shengtai.map((e, i) => {
              return (
                <section key={i} className=" text-center">
                  <img src={e.img} alt="img" />
                </section>
              );
            })}
          </section>
        </section>
      </section>
      {/* 营销工具 */}
      <section>
        <section className="text-center py-6 px-4">
          <p className="text-xl">高度集成化的智慧社区管理平台</p>
          <p className="text-lg my-4">通过微信、短信、电话、APP、小程序等与园区社区人员保持常态化沟通 可码上管理整个社区，提升工作效率和管理服务质量</p>
          <img src={marketing_img} alt="img" />
        </section>
      </section>
    
      <img src={hotline_img} alt="img" />
    </section>
  );
};
export default ScenicCooperation;
