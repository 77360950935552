import { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Banner from "../../component/Banner/index";
import PartnerLayout from "./PartnerLayout";
/* banner 图 */
import banner_img from "../../assets/image/index/2poster@2x.png";

/* 景区码能力介绍图片 */
import ability_1 from "../../assets/image/index/Fouryards1@2x.png";
import ability_2 from "../../assets/image/index/Fouryards2@2x.png";
import ability_3 from "../../assets/image/index/Fouryards3@2x.png";
import ability_4 from "../../assets/image/index/Fouryards4@2x.png";

/* 景区赋能-图片 */
import energize_img1 from "../../assets/image/index/FourEmpowerments1@2x.png";
import energize_img2 from "../../assets/image/index/FourEmpowerments4@2x.png";
import energize_img3 from "../../assets/image/index/FourEmpowerments4@2x.png";
import energize_img4 from "../../assets/image/index/FourEmpowerments4@2x.png";

/* 合作景区图片 */
import partner_scenic_img1 from "../../assets/image/index/Scenicspot1@2x.png";
import partner_scenic_img2 from "../../assets/image/index/Scenicspot2@2x.png";
import partner_scenic_img3 from "../../assets/image/index/Scenicspot3@2x.png";
import partner_scenic_img4 from "../../assets/image/index/Scenicspot4@2x.png";

/* 合作酒店图片 */
import partner_hotel_img1 from "../../assets/image/index/Hotel1@2x.png";
import partner_hotel_img2 from "../../assets/image/index/Hotel2@2x.png";
import partner_hotel_img3 from "../../assets/image/index/Hotel3@2x.png";
import partner_hotel_img4 from "../../assets/image/index/Hotel4@2x.png";

/* 合作商家图片 */
import partner_store_img1 from "../../assets/image/index/Business1@2x.png";
import partner_store_img2 from "../../assets/image/index/Business2@2x.png";
import partner_store_img3 from "../../assets/image/index/Business3@2x.png";
import partner_store_img4 from "../../assets/image/index/Business4@2x.png";

/* 底部智慧文旅 banner 图 */
import intelligent_banner from "../../assets/image/index/8.5poster@2x.png";

import axios from "axios";

const TailingLi = styled.div`
  position: relative;
  &.active::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
    bottom: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #01d267;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
`;
const ArticleLi = styled.li`
  .img-outer {
    width: 75px;
    height: 75px;
    overflow: hidden;
  }
  img {
    transition: all 0.3s;
  }
`;
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      energize_index: 0,
      energize_banner: energize_img1,
      news_list: [],
    };
    this.setNewsList();
  }
  async fetchNewsList() {
    return await axios("v1/news/get-list", {
      params: {
        page: 1,
        page_size: 4,
        cate_id: 0,
      },
    });
  }
  async setNewsList() {
    const { data: news } = await this.fetchNewsList();
    this.setState({
      news_list: news.list,
    });
  }
  ability_arr = [
    {
      title: "身份码",
      desc: "公安部接入身份证 出游不怕忘带身份证",
      icon: ability_1,
    },
    {
      title: "支付码",
      desc: "支持双盲支付 信号不好也可付款",
      icon: ability_2,
    },
    {
      title: "信用码",
      desc: "征信联动，先游后付，余额不足不尴尬",
      icon: ability_3,
    },
    {
      title: "健康码",
      desc: "甄别游客来源、 快速关联预警",
      icon: ability_4,
    },
  ];
  energize_arr = [
    {
      title: "服务赋能",
      banner: energize_img1,
    },
    {
      title: "流量赋能",
      banner: energize_img2,
    },
    {
      title: "创收赋能",
      banner: energize_img3,
    },
    {
      title: "数据赋能",
      banner: energize_img4,
    },
  ];
  partner_scenic_arr = [
    {
      title: "东部华侨城",
      img: partner_scenic_img1,
    },
    {
      title: "丹霞山风景名胜区",
      img: partner_scenic_img2,
    },
    {
      title: "黄山",
      img: partner_scenic_img3,
    },
    {
      title: "铁道游击队纪念公园",
      img: partner_scenic_img4,
    },
  ];
  partner_hotel_arr = [
    {
      title: "星语星愿酒店公寓",
      img: partner_hotel_img1,
    },
    {
      title: "上海华美国际酒店",
      img: partner_hotel_img2,
    },
    {
      title: "杭州碧海云天宾馆",
      img: partner_hotel_img3,
    },
    {
      title: "深圳圣淘沙酒店",
      img: partner_hotel_img4,
    },
  ];
  partner_store_arr = [
    {
      title: "运动健身",
      img: partner_store_img1,
    },
    {
      title: "休闲娱乐",
      img: partner_store_img2,
    },
    {
      title: "养生健康",
      img: partner_store_img3,
    },
    {
      title: "亲子教育",
      img: partner_store_img4,
    },
  ];
  handleClickEnergize(index) {
    this.setState({
      energize_index: index,
      energize_banner: this.energize_arr[index].banner,
    });
  }
  scrollToTop() {
    document.documentElement.scrollTop = 0;
  }
  render() {
    return (
      <main>
        <Banner img={banner_img}></Banner>
        {/* 景区码能力展示 */}
        <section className="text-center pb-16 dark-style">
          <p className=" text-xl py-4">景区码四大能力</p>
          <ul className="flex flex-wrap">
            {this.ability_arr.map((e, i) => {
              return (
                <li
                  className="flex justify-center items-center flex-col w-1/2 py-2"
                  key={i}
                >
                  <img src={e.icon} className="w-24 h-24" alt="img" />
                  <p className=" text-lg mt-1">{e.title}</p>
                  <span className="text-gray-400 inline-block w-5/6">
                    {e.desc}
                  </span>
                </li>
              );
            })}
          </ul>
        </section>
        {/* 景区码视频介绍 */}
        <section className="bg-gray-200 py-8">
          <section className="mx-auto">
            <p className="text-xl text-center">景区码：我是文旅行业码</p>
            <p className=" text-gray-500 text-lg my-2 w-3/4 mx-auto text-center">
              景区码联动全域景区和社区实现一码通行、一码互联、一码跨界，集合电子身份码、支付码、信用码、健康码等大数据能力，为人们提供码上生活的便利
            </p>
            <section className="px-4">
              <video
                src="http://www.cityfx.cn/static/default/home/assets/video/jqm2.mp4"
                controls
              ></video>
            </section>
          </section>
        </section>
        {/* 景区码四大赋能 */}
        <section className="p-8">
          <p className="text-xl text-center">四大赋能</p>
          <p className=" text-gray-500 text-lg my-2 px-4 mx-auto text-center">
            连接C端游客，增加景区内二消转化，实现大交通+大文旅+大消费的产业生态圈
          </p>
          <section className=" border-b-2 border-main-green-400 flex justify-around">
            {this.energize_arr.map((e, i) => {
              return (
                <TailingLi
                  className={
                    "text-lg py-3 " +
                    (this.state.energize_index === i ? " active" : "")
                  }
                  onClick={this.handleClickEnergize.bind(this, i)}
                  key={i}
                >
                  {e.title}
                </TailingLi>
              );
            })}
          </section>
          <img src={this.state.energize_banner} className="mt-8" alt="img" />
        </section>
        {/* 合作景区 */}
        <PartnerLayout
          arr={this.partner_scenic_arr}
          title="合作景区"
          desc="8000+智慧旅游项目案列 涵盖5A、4A景区"
        ></PartnerLayout>
        {/* 合作酒店 */}
        <PartnerLayout
          arr={this.partner_hotel_arr}
          title="合作酒店"
          desc="用户可享受100万+特惠酒店"
        ></PartnerLayout>
        {/* 合作商家 */}
        <PartnerLayout
          arr={this.partner_store_arr}
          title="合作商家"
          desc="用户可享受景区码会员30万+商家特惠活动"
        ></PartnerLayout>
        <section className="py-8 px-4">
          <img src={intelligent_banner} alt="img" />
        </section>
        <section className="bg-gray-200 py-8 px-4">
          <section className="mx-auto">
            <p className="text-xl text-center">企业新闻与行业资讯</p>
            <Link
              to="/news"
              className="my-4 flex justify-end items-center"
              onClick={this.scrollToTop}
            >
              查看更多
              <svg
                className="w-4 h-4 inline-block"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
            <section>
              <ul>
                {this.state.news_list.map((e, i) => {
                  return (
                    <ArticleLi
                      className="bg-white mb-2 p-1 rounded-sm dark-style"
                      key={i}
                    >
                      <Link
                        to={`/news?news_id=${e.id}`}
                        className="flex"
                        onClick={this.scrollToTop}
                      >
                        <section className="mr-1 flex-shrink-0 img-outer">
                          <img
                            className="w-20 h-20 object-cover"
                            src={e.img}
                            alt="img"
                          />
                        </section>
                        <section>
                          <p className="transition-all title">{e.title}</p>
                          <p className="text-gray-400 text-sm">{e.desc}</p>
                        </section>
                      </Link>
                    </ArticleLi>
                  );
                })}
              </ul>
            </section>
          </section>
        </section>
      </main>
    );
  }
}
export default Index;
