import icon_top from "../../assets/image/index/icon_top@2x.png";
const Contact = function () {
  function scrollToTop() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }
  return (
    <aside className="fixed right-4 bottom-20 transform overflow-hidden">
      <img src={icon_top} alt="top" className='transform scale-110 w-10' onClick={scrollToTop} />
    </aside>
  );
};
export default Contact;
