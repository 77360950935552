import styled from "styled-components";

const ImgOuter = styled.div`
  height: 7.6rem;
  overflow: hidden;
`;
const PartnerLayout = function (props) {
  return (
    <section className="py-8 px-4">
      <p className="text-xl text-center">{props.title}</p>
      <p className=" text-gray-500 text-lg my-2 mb-4 mx-auto text-center">
        {props.desc}
      </p>
      <ul className="grid grid-cols-2 grid-rows-2 gap-4">
        {props.arr.map((e, i) => {
          return (
            <li key={i}>
              <ImgOuter>
                <img src={e.img} alt="img" className='scale-105 transform' />
              </ImgOuter>
              <p className="text-center text-base mt-2">{e.title}</p>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default PartnerLayout;
