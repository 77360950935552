import Banner from "../../component/Banner/index";
import styled from "styled-components";

import banner_img from "../../assets/image/ecosystem_cooperation/poster@2x.png";

/* 应用合作图片 */
import app_img1 from "../../assets/image/ecosystem_cooperation/Cooperation1@2x.png";
import app_img2 from "../../assets/image/ecosystem_cooperation/Cooperation2@2x.png";
import app_img3 from "../../assets/image/ecosystem_cooperation/Cooperation3@2x.png";
import app_img4 from "../../assets/image/ecosystem_cooperation/Cooperation4@2x.png";
import app_img5 from "../../assets/image/ecosystem_cooperation/Cooperation5@2x.png";
import app_img6 from "../../assets/image/ecosystem_cooperation/Cooperation6@2x.png";

/* 4 大支持图片 */
import supporting_img1 from "../../assets/image/ecosystem_cooperation/Supportpolicy1@2x.png";
import supporting_img2 from "../../assets/image/ecosystem_cooperation/Supportpolicy2@2x.png";
import supporting_img3 from "../../assets/image/ecosystem_cooperation/Supportpolicy3@2x.png";
import supporting_img4 from "../../assets/image/ecosystem_cooperation/Supportpolicy4@2x.png";

/* 代理商图片 */
import agent_distribution_img from "../../assets/image/ecosystem_cooperation/Fivesystems@2x.png";

/* 盈利图片 */
import income_img1 from "../../assets/image/ecosystem_cooperation/profit1@2x.png";
import income_img2 from "../../assets/image/ecosystem_cooperation/profit2@2x.png";
import income_img3 from "../../assets/image/ecosystem_cooperation/profit3@2x.png";
import income_img4 from "../../assets/image/ecosystem_cooperation/profit4@2x.png";
import income_img5 from "../../assets/image/ecosystem_cooperation/profit5@2x.png";
import income_img6 from "../../assets/image/ecosystem_cooperation/profit6@2x.png";
import income_img7 from "../../assets/image/ecosystem_cooperation/profit7@2x.png";
import income_img8 from "../../assets/image/ecosystem_cooperation/profit8@2x.png";
import income_img9 from "../../assets/image/ecosystem_cooperation/profit9@2x.png";

/* 5 大扶持政策图片 */
import policy_img1 from "../../assets/image/ecosystem_cooperation/Support1@2x.png";
import policy_img2 from "../../assets/image/ecosystem_cooperation/Support2@2x.png";
import policy_img3 from "../../assets/image/ecosystem_cooperation/Support3@2x.png";
import policy_img4 from "../../assets/image/ecosystem_cooperation/Support4@2x.png";
import policy_img5 from "../../assets/image/ecosystem_cooperation/Support5@2x.png";

import { Link } from "react-router-dom";

const AppCooText = styled.article`
  position: relative;
  text-align: left;
  padding: 1rem;
  border: 1px solid #f4f4f4;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 1rem;
    width: 10px;
    height: 20px;
    background-color: #52ab5e;
  }
`;
const EcosystemCooperation = function () {
  const app_cooperation_arr = [
    {
      img: app_img1,
      text: "智慧口罩机",
    },
    {
      img: app_img2,
      text: "共享代步车",
    },
    {
      img: app_img3,
      text: "共享休息仓",
    },
    {
      img: app_img4,
      text: "智慧储物柜",
    },
    {
      img: app_img5,
      text: "共享充电宝",
    },
    {
      img: app_img6,
      text: "共享饮品机",
    },
  ];
  const help_policy_arr = [
    {
      img: policy_img1,
      text: "经营支持",
      desc:
        "给予更优政策，全力帮助 地区服务商开拓市场，开 发景区，协助地区服务商 搭建团队架构",
      // icon: policy_active_icon1,
    },
    {
      img: policy_img2,
      desc: "免费提供产品使用培训、 市场推广话术培训、 团队管理培训等",
      // icon: policy_active_icon2,
      text: "培训支持",
    },
    {
      img: policy_img3,
      desc:
        "强力的线下路演助推， 提高景区码知名度， 使地区服务商更快更顺 利开展",
      // icon: policy_active_icon3,
      text: "宣传支持",
    },
    {
      img: policy_img4,
      desc: "总部为每个合作伙伴提供 强大的后台管理，数据可 实时查看和跟进",
      // icon: policy_active_icon4,
      text: "技术支持",
    },
    {
      img: policy_img5,
      desc:
        "景区码定期委派市场 团队到现场帮扶，协助 服务商组建地推团队， 为地区服务商保驾护航",
      // icon: policy_active_icon5,
      text: "团队支持",
    },
  ];
  return (
    <section>
      <Banner img={banner_img} path="/about/contact"></Banner>
      {/* 应用合作 */}
      <section className="text-center mx-auto px-4">
        <p className="pt-6 text-xl">应用合作</p>
        <p className=" py-3 text-lg">增加触达场景 提升景区收益</p>
        <AppCooText>
          景区码为应用伙伴们接入景区、商业综合体等展现服务场景同时通过红包、卡券等互动带来更多有粘度的客户景区码与应用伙伴们共同成长。
        </AppCooText>
        <section className="grid grid-cols-3 grid-rows-2 gap-4 py-8">
          {app_cooperation_arr.map((e, i) => (
            <section className="flex flex-wrap justify-between" key={i}>
              <section className="">
                <img src={e.img} alt="img" />
                <p>{e.text}</p>
              </section>
            </section>
          ))}
        </section>
      </section>

      {/* 四大支持政策 */}
      <p className="text-xl text-center">应用伙伴四大支持政策</p>
      <section className="pt-8 px-4 grid grid-cols-2 grid-rows-2 gap-x-4 gap-y-2">
        <img src={supporting_img1} alt="img" />
        <img src={supporting_img2} alt="img" />
        <img src={supporting_img3} alt="img" />
        <img src={supporting_img4} alt="img" />
      </section>
      {/* 服务、代理商 */}
      <section className="py-6">
        <img src={agent_distribution_img} alt="img" />
        <section className="ml-8 mt-4">
          <p className="text-xl">服务商、合伙人</p>
          <p className="text-lg mt-4">
            在全国34个省景区码设立了3000多个地区服务商，30万个合伙人。为用户、景区、商家合作伙伴提供服务携手共赢。
          </p>
        </section>
      </section>
      {/* 九大盈利板块 */}
      <section className="bg-gray-100 py-6 px-4">
        <p className="text-xl text-center pb-4">九大盈利板块</p>
        <section className=" grid grid-cols-3 grid-rows-3 gap-2">
          <img src={income_img1} alt="img" />
          <img src={income_img2} alt="img" />
          <img src={income_img3} alt="img" />
          <img src={income_img4} alt="img" />
          <img src={income_img5} alt="img" />
          <img src={income_img6} alt="img" />
          <img src={income_img7} alt="img" />
          <img src={income_img8} alt="img" />
          <img src={income_img9} alt="img" />
        </section>
      </section>
      {/* 景区码5大扶持政策 */}
      <section className="pb-6">
        <p className="text-xl py-6 text-center">景区码5大扶持政策</p>
        <section className="flex text-center divide-x divide-gray-200">
          {help_policy_arr.map((e, i) => (
            <section className="flex-1" key={i}>
              <img src={e.img} alt="img" className=" w-12 h-12 mx-auto" />
              <p className="text-base mt-2">{e.text}</p>
            </section>
          ))}
        </section>
      </section>
    </section>
  );
};
export default EcosystemCooperation;
