import { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";

import { withRouter } from "react-router";

import Banner from "../../component/Banner/index";
import banner_img from "../../assets/image/news/poster.png";

import React from "react";

/* 关于我们组件 */
import Intro from "./intro";
/* 加入我们组件 */
import Join from "./join";
/* 联系我们组件 */
import Contact from "./contact";
/* 产品介绍组件 */
import Product from "./product";
import styled from "styled-components";

const NavLink = styled(Link)`
  background-color: #327fdb;
  &.active {
    background-color: #0a54ab;
  }
`;

class Container extends Component {
  aside_nav_arr = [
    {
      title: "关于我们",
      to: "",
    },
    {
      title: "产品介绍",
      to: "/product",
    },
    {
      title: "联系我们",
      to: "/contact",
    },
    {
      title: "加入我们",
      to: "/join",
    },
  ];
  routes = [
    {
      path: "",
      exact: true,
      main: Intro,
    },
    {
      path: "/join",
      exact: false,
      main: Join,
    },
    {
      path: "/contact",
      exact: false,
      main: Contact,
    },
    {
      path: "/product",
      exact: false,
      main: Product,
    },
  ];

  getAsideNavIndex() {
    let index = 0;

    const { pathname } = this.props.location;
    const { path: first_path } = this.props.match;

    // debugger;
    this.aside_nav_arr.some((e, i) => {
      const is_match = pathname === `${first_path}${e.to}`;
      if (is_match) {
        index = i;
        return true;
      }
    });
    return index;
  }
  handleClick(i, e, ...rest) {
    console.log(this);
    console.log(i);
    console.log(e);
    console.log(rest);
  }
  render() {
    // console.log(this.props);
    const { path: first_path } = this.props.match;

    return (
      <section>
        <Banner img={banner_img}></Banner>
        <aside className="mb-6 bg-gray-100 grid grid-cols-4">
          {this.aside_nav_arr.map((e, i) => {
            return (
              <NavLink
                to={`${first_path}${e.to}`}
                className={`py-4 text-center transition text-white text-xl ${
                  this.getAsideNavIndex() === i ? "active" : ""
                }`}
                key={i}
                onClick={(e) => this.handleClick(i, e, 3)}
              >
                {e.title}
              </NavLink>
            );
          })}
        </aside>
        <Switch>
          {this.routes.map((route, index) => {
            // console.log(`${first_path}${route.path}`);
            return (
              <Route
                key={index}
                path={`${first_path}${route.path}`}
                exact={route.exact}
                children={<route.main />}
              />
            );
          })}
        </Switch>
      </section>
    );
  }
}

export default withRouter(Container);
