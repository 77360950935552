import styled from "styled-components";
import { Link } from "react-router-dom";

const BannerImg = styled.img`
  width: 100%;
  height: 15rem;
  object-fit: cover;
`;
const Banner = function (props) {
  return (
    <a href={props.path ? "tel:0755-89201956" : "#"} to={props.path ?? "#"}>
      <BannerImg src={props.img}></BannerImg>
    </a>
  );
};
export default Banner;
